<template>
  <!-- 主要內容 -->
  <section class="buycourse pt-3 pb-8">
    <div class="container">
      <div class="row cs-course-list">
        <div v-for="item in ListData" :key="item.examId" class="col-12 col-sm-6 col-lg-4 mb-6">
          <div class="cs-card h-100" data-style="3">
            <div class="card-title">
              <span>{{ item.examName }}</span>
            </div>
            <ul class="card-body">
              <li v-for="itemCategory in item.categoryList" :key="itemCategory.categoryId">
                <router-link
                  :to="{
                    name: 'ClassRoom',
                    params: {
                      examId: item.examId,
                      categoryId: itemCategory.categoryId,
                    },
                  }"
                >
                  {{ itemCategory.categoryName }}</router-link
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { GetBuyCourseExamAndCategory } from '@/api/BuyCourseApi';

export default {
  data() {
    // 建立你的初始化 model data
    return {
      ListData: [],
    };
  },
  created() {
    this.GetList();
  },
  methods: {
    GetList() {
      GetBuyCourseExamAndCategory().then((response) => {
        if (response.data.statusCode === 0) {
          this.ListData = response.data.data;
        }
      });
    },
  },
};
</script>

<style></style>
